var app = angular.module('MyApp', []);

function get_params_from_url(url) {
    var url_params = {};
    url = url.split('/?');
    if (url.length === 2) {
        var params = url[1].split('&');
        angular.forEach(params, function (v) {
            v = v.split('=');
            url_params[v[0]] = v[1];
        });
    }
    return url_params;
}

app.directive('setFocus', ["$timeout",
    function ($timeout) {
        return function (scope, element, attrs) {
            scope.$watch(attrs.setFocus,
                    function (newValue) {
                        $timeout(function () {
                            if (newValue) {
                                element[0].focus();
                            }
                        });
                    }, true);
        };
    }
]);

app.directive("deferredCloak", function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            attrs.$set("deferredCloak", undefined);
            element.removeClass("deferred-cloak");
        }
    };
});

app.directive('validateEmail', function () {
    var EMAIL_REGEXP = /^[_a-z0-9\+]+(\.[_a-z0-9\+]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/i;

    return {
        require: 'ngModel',
        restrict: '',
        link: function (scope, elm, attrs, ctrl) {
            // only apply the validator if ngModel is present and Angular has added the email validator
            if (ctrl && ctrl.$validators.email) {

                // this will overwrite the default Angular email validator
                ctrl.$validators.email = function (modelValue) {
                    return ctrl.$isEmpty(modelValue) || EMAIL_REGEXP.test(modelValue);
                };
            }
        }
    };
});

app.controller('ContactFormController', ["$scope", "$http",
    function ($scope, $http) {
        $scope.contactFormSubmitting = false;
        $scope.contactFormSubmited = false;
        $scope.contactFormModel = {};

        $scope.submitContactForm = function () {
            $scope.contactFormSubmited = false;
            $scope.contactFormError = false;
            if ($scope.contactForm.$valid) {
                $scope.contactFormSubmitting = true;
                $http({
                    url: jsData.ajaxUrl,
                    method: 'POST',
                    data: jQuery.param({
                        action: 'contact_form',
                        nonce: jsData.nonce,
                        data: $scope.contactFormModel
                    }),
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                }).then(function (data) {
                    $scope.contactFormSubmitting = false;
                    if (data.data.success) {
                        $scope.contactFormSubmited = true;
                        $scope.contactForm.$setPristine();
                        $scope.contactForm.$setUntouched();
                        $scope.contactFormModel = {};
                    } else if (!data.data.success) {
                        $scope.contactFormError = true;
                    }
                });
            }
        };
    }
]);

// Gmap
function initMap() {
    // Create a map object and specify the DOM element for display.
    if (jQuery('#gmap').length) {
        var myLatLng = new google.maps.LatLng(parseFloat(jsData.gmap_center.lat), parseFloat(jsData.gmap_center.lng));
        var map = new google.maps.Map(document.getElementById('gmap'), {
            center: myLatLng,
            scrollwheel: false,
            zoom: parseInt(jsData.gmap_zoom),
            styles: jQuery.parseJSON(jsData.gmap_styles)
        });
        var marker_image = {
            url: jsData.gmap_marker.url,
            // This marker is 20 pixels wide by 32 pixels high.
            size: new google.maps.Size(49, 47),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(0, 47)
        };
        var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            icon: marker_image
        });
        google.maps.event.addDomListener(window, "resize", function () {
            google.maps.event.trigger(map, "resize");
            map.setCenter(myLatLng);
        });
    }
}

(function ($) {
    // WoW.js
    new WOW().init();
    // Isotope
    var $grid = null;
    $(window).on('load statechangecomplete', function () {
        // Manually Start Angular App !!
        // DO NOT add ng-app attribute in HTML !!
        angular.element(document.getElementById('content-inner')).ready(function () {
            angular.bootstrap(document.getElementById('content-inner'), ["MyApp"]);
        });


        // Fancybox
        $("a[href$='.jpg'],a[href$='.jpeg'],a[href$='.png'],a[href$='.gif']").attr('rel', 'gallery').fancybox({
            padding: 0,
            openEffect: 'elastic'
        });

        initMap();

        if ($('.grid').length) {
            $grid = $('.grid').isotope({
                itemSelector: '.grid-item'
            });
//            var last_slug = window.location.pathname.replace(/.*\/([^\/]+)\/?$/gi, '$1');
//            var hash = jsData.realisations_categories[0];
//            if(jsData.realisations_categories.indexOf(last_slug) > -1) {
//                hash = last_slug;
//            }
//            console.log('HASH', hash);
//            console.log('filter length', $('.filter_' + hash).length);
//            if (hash /*&& $('.filter_' + hash).length*/) {
//                $('.isotope-filter').removeClass('active');
//                $('[data-filter=".filter_' + hash + '"]').addClass('active');
//                $grid.isotope({
//                    filter: '.isotope-filter-item.filter_' + hash
//                });
//            }
        }

        // Slick Carousel
        var slick_prev_arrow = '<button type="button" class="slick-prev"><i class="icon-left-open-big"></i></button>';
        var slick_next_arrow = '<button type="button" class="slick-next"><i class="icon-right-open-big"></i></button>';
        $('.slick-simple').slick({
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 2,
            prevArrow: slick_prev_arrow,
            nextArrow: slick_next_arrow,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
        // Auto Hight Textarea
        autosize($('textarea.autosize'));
        // Add animated border and remove with condition when focus and blur
        if ($('.fg-line').length) {
            $('body').on('focus', '.fg-line .form-control', function () {
                $(this).closest('.fg-line').addClass('fg-toggled');
            });

            $('body').on('blur', '.form-control', function () {
                var p = $(this).closest('.form-group');
                var i = p.find('.form-control').val();

                if (p.hasClass('fg-float')) {
                    if (!i.length) {
                        $(this).closest('.fg-line').removeClass('fg-toggled');
                    }
                } else {
                    $(this).closest('.fg-line').removeClass('fg-toggled');
                }
            });
        }
    });
    // Body events
    $('body')
            .on('click', '[data-toggle="collapse-horizontal"]', function (e) {
                e.preventDefault();
                var $this = $(this);
                var $target = $($this.data('target'));
                if ($target.hasClass('in')) {
                    $target.removeClass('in');
                    $this.addClass('collapsed').attr('aria-expanded', 'false');
                } else {
                    $target.addClass('in');
                    $this.removeClass('collapsed').attr('aria-expanded', 'true');
                }
            })
            .on('click', '.isotope-filter', function (e) {
                e.preventDefault();
                var $this = $(this);
                $('.isotope-filter').removeClass('active');
                $this.addClass('active');
                $grid.isotope({
                    filter: '.isotope-filter-item' + $this.data('filter')
                });
            });
})(jQuery);
